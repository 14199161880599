// setup index to preference

import { extendTheme } from "@chakra-ui/react";

import { colors } from "src/theme/colors";
import { typography } from "src/theme/typography";

const theme = extendTheme({
  styles: {
    global: () => ({
      ...typography,
    }),
  },
  fonts: {},
  colors,
  components: {
    Button: {
      baseStyle: {
        textTransform: "Capitalize",
        borderRadius: "50px",
        fontWeight: "400",
        padding: "16px 32px",
        fontFamily: '"General Sans", sans-serif !important',
      },
      sizes: {
        sm: {
          fontSize: '18px',
          fontWeight: 600,
        },
        md: {
          fontSize: '18px',
          fontWeight: 600,
        },
        lg: {
          fontSize: '18px',
          fontWeight: 600,
        },
        xl: {
          fontSize: '18px',
          fontWeight: 600,
        },
        full: {
          fontSize: '18px',
          fontWeight: 600,
        },
      },
      variants: {
        primary: {
          bg: "primary",
          color: "#fff",
          fontStyle: "normal",
          // borderWidth: "1px",
          // borderColor: "primary",
          // borderStyle: "solid",
          _hover: {
            bg: '#228b22 !important'
            // bg: "#0b0a0adb",
            // bg: '#000',
            // color: "#fff",
          },
        },
        secondary: {
          color: "primary",
          fontStyle: "normal",
          borderWidth: "1px",
          borderColor: "primary",
          borderStyle: "solid",
          _hover: {
            backgroundColor: "#eeeeee",
            color: "primary",
          },
        },
        tertiary: {
          bg: "tertiary",
          color: "#fff",
          fontStyle: "normal",
          borderWidth: "1px",
          borderColor: "tertiary",
          borderStyle: "solid",
          _hover: {
            bg: "#c72498db",
            color: "#fff",
          },
        },
        danger: {
          bg: "#e53e3e",
          color: "#fff",
          fontStyle: "normal",
          borderWidth: "1px",
          borderColor: "#e53e3e",
          borderStyle: "solid",
          padding: "20px 40px !important",
          width: "100%",
          _hover: {
            bg: "#c53030",
            color: "#fff",
          },
        },
        dark: {
          bg: "#7D7D7D",
          color: "#fff",
          fontStyle: "normal",
          borderWidth: "1px",
          borderColor: "#7D7D7D",
          borderStyle: "solid",
          padding: "20px 40px !important",
          width: "100%",
          _hover: {
            bg: "#7d7d7dd9",
            color: "#fff",
            borderColor: "#7d7d7dd9",
          },
        },
        offset: {
          bg: "blue",
          color: "#fff",
          fontStyle: "normal",
          borderWidth: "1px",
          borderColor: "#7D7D7D",
          borderStyle: "solid",
          padding: "20px 40px !important",
          width: "100%",
          _hover: {
            bg: "#7d7d7dd9",
            color: "#fff",
            borderColor: "#7d7d7dd9",
          },
        },
      },
      defaultProps: {
        variant: "primary",
        size: "lg",
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            padding: "12px 14px",
            fontSize: "14px",
            borderRadius: "8px",
          },
        },
      },
      defaultProps: {
        variant: "outline",
        size: "md",
        focusBorderColor: "primary",
      },
    },
    Checkbox: {
      baseStyle: {
        label: {
          color: "primary",
          fontSize: "28px !important",
        },
        control: {
          borderColor: "primary",
          _checked: {
            bg: "primary",
            color: "white",
            borderColor: "primary",
            _hover: {
              bg: "primary",
              color: "white",
              borderColor: "primary",
            },
          },
        },
      },
    },
    Tabs: {
      variants: {
        "soft-rounded": {
          tab: {
            fontSize: "14px",
            fontWeight: 400,
            _selected: {
              bg: "primary",
              color: "#fff",
            },
          },
        },
        line: {
          tab: {
            fontSize: "14px",
            fontWeight: 400,
            _selected: {
              color: "primary",
              borderBottomColor: "red.500",
            },
          },
        },
      },
    },
    Accordion: {
      parts: ["item"],
      baseStyle: {
        item: {
          _last: {
            borderBottomWidth: 0,
            borderColor: "transparent",
          },
        },
        panel: {
          borderBottom: 0,
          borderColor: "transparent",
        },
      },
    },
    ChakraAccordionPanel: {
      baseStyle: {
        borderBottom: 0,
        borderColor: "transparent",
      },
    },
  },
});

export default theme;
